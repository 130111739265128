// Vision Pilates

import visionWindow from "@assets/case_study/vision_pilates/window.webp";
import visionBrochure from "@assets/case_study/vision_pilates/brochure.webp";
import visionCards from "@assets/case_study/vision_pilates/business-card.webp";
import visionMats from "@assets/case_study/vision_pilates/mats.webp";
import visionLaptop from "@assets/case_study/vision_pilates/laptop.webp";

// Ultra Protect

import ultraProducts from "@assets/case_study/ultra_protect/products.webp";
import ultraBusinessCard2 from "@assets/case_study/ultra_protect/business-cards2.webp";
import ultraBillboard from "@assets/case_study/ultra_protect/billboard.webp";
import ultraStickers from "@assets/case_study/ultra_protect/stickers.webp";

// Square Massage

import squareCard1 from "@assets/case_study/square_massage/business-card1.webp";
import squareCard2 from "@assets/case_study/square_massage/business-card2.webp";
import squareCard3 from "@assets/case_study/square_massage/business-card3.webp";
import squareCard4 from "@assets/case_study/square_massage/business-card4.webp";
import squareInstagramPosts from "@assets/case_study/square_massage/instagram-posts.webp";
import squareInstagramStory from "@assets/case_study/square_massage/instagram-story.webp";
import squareStore from "@assets/case_study/square_massage/store-front.webp";
import squareTrifold from "@assets/case_study/square_massage/trifold.webp";
import squareDecal from "@assets/case_study/square_massage/window-decal.webp";

export const caseStudyData = [
  {
    title: "Vision Pilates",
    description: "A website for a pilates studio in New York City.",
    images: [
      visionCards,
      visionBrochure,
      visionMats,
      visionLaptop,
      visionWindow,
    ],
    url: "/case-studies/vision-pilates",
  },
  {
    title: "Ultra Protect",
    description: "A website for a dog treat company.",
    images: [ultraProducts, ultraBusinessCard2, ultraBillboard, ultraStickers],
    url: "/case-studies/ultra-protect",
  },
  {
    title: "Square Massage",
    description: "A website for Square Massage.",
    images: [
      squareCard2,
      squareStore,
      squareInstagramStory,
      squareTrifold,
      squareCard4,
      squareDecal,
      squareInstagramPosts,
    ],
    url: "/case-studies/square-massage",
  },
  {
    title: "Vision Pilates",
    description: "A website for a pilates studio in New York City.",
    images: [
      visionCards,
      visionBrochure,
      visionMats,
      visionLaptop,
      visionWindow,
    ],
    url: "/case-studies/vision-pilates",
  },
];

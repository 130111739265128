import React, { FC } from "react";
import { ResumeSC } from "./Resume.styled";
import JpgIzzy from "@assets/jpg/izzy.jpg";

const Resume: FC = () => {
  return (
    <ResumeSC>
      <div className="container">
        <div className="header">
          <img src={JpgIzzy} />
          <h1>
            Isabelle Buirski
            <br />
            <span>Designer & Illustrator</span>
          </h1>

          <div className="sub-header">
            <p>Sunshine Coast, QLD</p>
            <a href="">+61 123456789</a>
            <a href="">hello@izzybuirski.com</a>
          </div>
        </div>
        <div className="description">
          <p>
            I am a creative designer and illustrator with a passion for brand,
            product, and logo design.
          </p>
          <p>
            <span>
              I love what I do, so feel free to reach out any time no matter
              where you are in the world.
            </span>
          </p>
          <h3></h3>
        </div>
        <div className="information">
          <div className="availability">
            <h3>Availability</h3>
            <p>
              I am currently open to both freelance projects and longer term
              opportunities. Reach out to explore working together!
            </p>
          </div>

          <div className="links">
            <h3>Links</h3>
            <a href="" target="_blank">
              LinkedIn
            </a>
            <a href="" target="_blank">
              Dribble
            </a>
            <a href="" target="_blank">
              Pinterest
            </a>
          </div>
          <div className="contact">
            <h3>Contact</h3>
            <p>
              I am most active and reachable{" "}
              <a href="mailto:hello@izzybuirski.com">via email</a>.
            </p>
          </div>
        </div>
      </div>
    </ResumeSC>
  );
};

export default Resume;

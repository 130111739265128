import React, { FC } from "react";
import { motion } from "framer-motion";
import { fadeIn } from "@utils/animation";

interface AnimatedTextProps {
  children: React.ReactNode;
}

const AnimatedFadeDiv: FC<AnimatedTextProps> = ({ children }) => {
  return (
    <motion.div
      initial={fadeIn.hidden}
      whileInView={fadeIn.visible}
      viewport={{ once: true }}
    >
      {children}
    </motion.div>
  );
};

export default AnimatedFadeDiv;

import React, { FC } from "react";
import { FooterSC } from "./Footer.styled";
import { ButtonPrimarySC } from "@components/Buttons/Buttons.styled";
import axios from "axios";

const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
  event.preventDefault(); // Prevent the default form submission behavior

  // Get the email value from the input field
  const email = event.currentTarget.email.value;

  try {
    // Make the API request to Mailchimp
    const response = await axios.post(
      "MAILCHIMP_API_URL",
      {
        email_address: email,
        status: "subscribed",
      },
      {
        headers: {
          Authorization: "Bearer MAILCHIMP_API_KEY",
          "Content-Type": "application/json",
        },
      }
    );

    // Handle the response
    console.log(response.data); // You can customize this based on your needs

    // Clear the input field after successful submission
    event.currentTarget.email.value = "";
  } catch (error) {
    // Handle any errors
    console.error(error);
  }
};

const Footer: FC = () => {
  const currentYear = new Date().getFullYear();
  return (
    <FooterSC>
      <div className="upper-footer">
        <div className="newsletter">
          <h1>Sign up to my newsletter</h1>
          <p>
            Sign up to receive my design & illustration insights.
            <br />I promise not to flood your inbox.
          </p>
          <form onSubmit={handleSubmit}>
            <input type="text" name="email" placeholder="Email Address" />
            <ButtonPrimarySC type="submit">Sign Up</ButtonPrimarySC>
          </form>
          <p>I respect your privacy</p>
        </div>
        <div className="content">
          <h1>Keep in Touch</h1>
          <p>
            I am always sharing & chatting over on Instagram at @studioizzyb
          </p>
          {/* <div className="images">
            <div className="image">
              <img
                src="https://source.unsplash.com/720x600/?fashion"
                alt="fashion"
              />
            </div>
            <div className="image">
              <img
                src="https://source.unsplash.com/720x600/?fashion"
                alt="fashion"
              />
            </div>
            <div className="image">
              <img
                src="https://source.unsplash.com/720x600/?fashion"
                alt="fashion"
              />
            </div>
            <div className="image">
              <img
                src="https://source.unsplash.com/720x600/?fashion"
                alt="fashion"
              />
            </div>
          </div> */}
        </div>
      </div>
      <div className="lower-footer">
        <div className="copyright">
          © {currentYear} Isabelle Buirski. All rights reserved.
        </div>
        <div className="socials"></div>
      </div>
    </FooterSC>
  );
};

export default Footer;

import styled from "styled-components";

export const ContactSC = styled.div`
  text-align: center;
  background: white;
  padding: 4rem 0rem;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;

  p {
    text-transform: uppercase;
    margin-bottom: 0px;
    z-index: 2;
  }

  svg {
    max-width: 100px;
  }

  h1 {
    font-size: 5rem;
    font-weight: 400;
    z-index: 2;
    margin: 0px;

    span {
      display: inline-block;
      transform: rotate(90deg);
    }
  }

  @media only screen and (max-width: 1024px) {
    padding: 2rem 1rem;

    h1 {
      font-size: 2rem;
    }

    p {
      font-size: 0.8rem;
    }
  }
`;

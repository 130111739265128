import React, { FC } from "react";
import { NavbarSC } from "./Navbar.styled";
import { ButtonPrimarySC } from "@components/Buttons/Buttons.styled";
import IzzyPng from "@assets/png/izzy-logo.png";
import { Link } from "react-router-dom";

const links = [
  { title: "Home", path: "/" },
  { title: "About", path: "/about" },
  // { title: "Projects", path: "/projects" },
  { title: "Contact", path: "/contact" },
];

const Navbar: FC = () => {
  const [menuOpen, setMenuOpen] = React.useState(false);
  return (
    <NavbarSC>
      <button className="hamburger" onClick={() => setMenuOpen(!menuOpen)}>
        <div
          style={{
            transform: menuOpen ? "rotate(45deg)" : "none",
            background: menuOpen ? "#fdfbf6" : "#242422",
          }}
          className="line line-top"
        />
        <div
          style={{
            transform: menuOpen ? "translateX(-1px) rotate(-45deg)" : "none",
            background: menuOpen ? "#fdfbf6" : "#242422",
          }}
          className="line line-bottom"
        />
      </button>
      <div
        style={{ height: menuOpen ? "100vh" : 0 }}
        className="menu-container"
      >
        {menuOpen ? (
          <div className="menu-list">
            {links.map((link, index) => (
              <div
                className="item-container"
                key={index}
                style={{ animationDelay: `${index * 0.1}s` }}
              >
                <div className="item-row">
                  <Link
                    key={index}
                    to={link.path}
                    className="menu-item"
                    style={{
                      animationDelay: `${index * 0.1}s`,
                    }}
                    onClick={() => setMenuOpen(false)}
                  >
                    <p>{link.title}</p>
                  </Link>
                  <p className="number">{index + 1}</p>
                </div>
                <div
                  className="line"
                  style={{ animationDelay: `${index * 0.1}s` }}
                />
              </div>
            ))}
            {/* <div className="social">
              {links.social.map((social, index) => (
                <a
                  key={index}
                  className="social-platform"
                  href={social.path}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => setMenuOpen(false)}
                >
                  <SocialIcon platform={social.title} />
                </a>
              ))}
            </div> */}
          </div>
        ) : null}
      </div>
      <div className="nav-links">
        {links.map((link) => (
          <div className="nav-link">
            <Link to={link.path}>{link.title}</Link>
          </div>
        ))}
      </div>
      <div className="brand">
        <Link to="/">
          <img src={IzzyPng} alt="logo" />
        </Link>
      </div>
      <div className="cta-btn">
        <Link to="/contact">
          <ButtonPrimarySC>Contact</ButtonPrimarySC>
        </Link>
      </div>
    </NavbarSC>
  );
};

export default Navbar;

import styled from "styled-components";

export const ButtonSC = styled.button`
  border-radius: 0px;
  padding: 0.5rem 1rem;
  outline: none;
  cursor: pointer;
`;

export const ButtonPrimarySC = styled(ButtonSC)`
  background-color: var(--secondary-color);
  border: none;
  color: var(--white);
  transition: 0.5s;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  &:hover {
    opacity: 0.8;
  }
`;

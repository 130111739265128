import styled from "styled-components";

export const ResumeSC = styled.div`
  background: white;
  padding: 4rem 0rem;

  .container {
    max-width: 1000px;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .header {
    img {
      max-width: 200px;
      border-radius: 9999px;
    }

    h1 {
      font-weight: 400;
      margin: 1rem 0rem;
      letter-spacing: -0.02em;
      font-size: 5rem;

      span {
        color: var(--secondary-color);
      }
    }

    .sub-header {
      display: flex;
      gap: 1rem;
      align-items: center;
      justify-content: center;

      a {
        color: var(--black);
        transition: 0.5s;
        &:hover {
          color: var(--secondary-color);
        }
      }
    }
  }

  .description {
    p {
      line-height: 160%;
      font-size: 1.5rem;
      margin: 0px;
    }
  }

  .information {
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: left;
    gap: 4rem;
    h3 {
      font-size: 1.5rem;
      margin-bottom: 1rem;
      text-transform: uppercase;
      font-weight: 600;
    }

    p,
    a {
      line-height: 160%;
      font-size: 1.25rem;
      margin: 0px;
    }
  }

  .links {
    flex-direction: column;
    display: flex;

    a {
      color: var(--black);
      transition: 0.5s;
      &:hover {
        color: var(--secondary-color);
      }
    }
  }

  .experience {
    padding-top: 2rem;
    margin-top: 2rem;
    border-top: 0.75px solid var(--black);
    display: grid;
    grid-template-columns: 1fr 1fr;

    .gigs {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      text-align: left;

      h2 {
        font-size: 1.5rem;
        margin-bottom: 0.5rem;
      }

      h3 {
        font-size: 1rem;
        margin-top: 0px;
        color: var(--secondary-color);
      }

      p {
        font-size: 1.15rem;
      }
    }

    h1 {
      font-weight: 400;
      margin: 1rem 0rem;
      letter-spacing: -0.02em;
      font-size: 5rem;
      text-align: left;

      span {
        color: var(--secondary-color);
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    padding: 2rem 1rem;

    .container {
      gap: 1rem;
    }
    .header {
      img {
        max-width: 150px;
      }
    }

    h1 {
      font-size: 2.5rem !important;
    }

    .sub-header {
      font-size: 0.8rem;
    }

    p {
      font-size: 1rem !important;
    }

    .information {
      grid-template-columns: 1fr;
      text-align: center;
    }

    .experience {
      grid-template-columns: 1fr;
    }
  }
`;

import React, { FC, useState, useEffect } from "react";
import { caseStudyData } from "../caseStudyData";
import { CaseStudyCardSC } from "../CaseStudies.styled";

type TCaseStudyCard = {
  key: number;
  images: string[];
  title: string;
  description: string;
  url: string;
};

const CaseStudyCard: FC<TCaseStudyCard> = ({
  key,
  images,
  title,
  description,
  url,
}) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (isHovered) {
      interval = setInterval(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
      }, 1000);
    } else {
      setCurrentImageIndex(0);
    }

    return () => clearInterval(interval);
  }, [isHovered, images.length]);

  return (
    <CaseStudyCardSC
      key={key}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      to={url}
    >
      <div className="case-study-image">
        <img src={images[currentImageIndex]} alt={title} />
      </div>
      <div className="case-study-content">
        <h1>{title}</h1>
      </div>
    </CaseStudyCardSC>
  );
};

export default CaseStudyCard;

import React, { FC } from "react";
import { HomeHeroSC, HomeSC } from "./Home.styled";
import Gallery from "./components/Gallery";
import { Link } from "react-router-dom";
import CaseStudies from "@pages/CaseStudies";

const Home: FC = () => {
  return (
    <HomeSC>
      <div className="header">
        <h1>
          I Craft Compelling Narratives,
          <br />
          Using Design & Branding.
        </h1>
      </div>
      <CaseStudies />
      {/* <Gallery /> */}
    </HomeSC>
  );
};

export default Home;

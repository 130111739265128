export const revealText = {
  hidden: {
    y: "99%",
    transition: { ease: [0.455, 0.03, 0.515, 0.955], duration: 1 },
  },
  visible: {
    y: 0,
    transition: { ease: [0.455, 0.03, 0.515, 0.955], duration: 1 },
  },
};

export const fadePage = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { ease: [0.455, 0.03, 0.515, 0.955], duration: 0.5 },
};

export const fadeIn = {
  hidden: {
    opacity: 0,
    transition: { ease: [0.455, 0.03, 0.515, 0.955], duration: 0.75 },
  },
  visible: {
    opacity: 1,
    transition: { ease: [0.455, 0.03, 0.515, 0.955], duration: 0.75 },
  },
};

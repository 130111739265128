import styled, { keyframes } from "styled-components";

const appear = keyframes`
0% {
  opacity: 0;
}
100% {
  opacity: 1;
}
`;

const slideIn = keyframes`
0% {
  transform: translateX(-2%);
}
100% {
  transform: translateX(0);
}
`;

const shrink = keyframes`
0% {
  width: 95%;
}
100% {
  width: 90%;
}
`;

export const NavbarSC = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  padding: 1rem 4rem;

  .brand {
    img {
      height: 60px;
    }
  }

  .mobile-menu {
    display: none;
  }

  .nav-links {
    display: flex;
    gap: 1rem;
    align-items: center;

    a {
      color: black;
      text-decoration: none;
      letter-spacing: 0.08em;
      font-size: 0.9rem;
    }
  }

  .cta-btn {
    display: flex;
    justify-content: flex-end;
  }

  .menu-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;

    overflow: hidden;
    display: flex;
    flex-direction: column;
    background: #242422;
    color: #fafafa;
    transition: height 0.3s ease;
    z-index: 2;
  }

  .hamburger {
    height: 32px;
    width: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 4px;
    background: none;
    border: none;
    z-index: 3;
    gap: 2px;

    @media only screen and (min-width: 1024px) {
      display: none;
    }

    .line {
      height: 2px;
      width: 20px;
      transition: all 0.2s ease;
      margin-bottom: 9px;
    }

    .line-top {
      transform-origin: top left;
    }

    .line-bottom {
      transform-origin: top left;
    }
  }

  .item-container {
    opacity: 0;
    animation: 1s ${appear} forwards;
    margin: auto;

    .item-row {
      display: flex;
      justify-content: space-between;
      width: 90%;
      align-items: center;
      margin: 0 auto;
      font-family: baker-signet-web;
      font-weight: 400;

      .number {
        font-size: 1.5rem;
        text-align: right;

        font-style: italic;
      }
    }
  }

  .menu-item {
    padding: 1rem 0rem;
    cursor: pointer;
    transition: color 0.2s ease-in-out;
    animation: 0.5s ${slideIn} forwards;
    color: #fdfbf6;
    text-decoration: none;

    p {
      margin: 0px;
      font-size: 1.5rem;
      text-transform: uppercase;
      font-weight: 400;
    }
  }

  .menu-list {
    padding-top: 4rem;

    .social {
      text-align: center;
      margin-top: 2rem;
      justify-content: center;
    }

    a {
      color: #fdfbf6;
    }

    .line {
      height: 1px;
      background: gray;
      width: 100%;
      margin: 0 auto;
      animation: 0.5s ${shrink} forwards;
    }
  }

  @media only screen and (max-width: 1024px) {
    grid-template-columns: 40px 150px auto;
    padding: 1rem 1rem;
    .mobile-menu {
      display: block;
    }

    .brand {
      img {
        width: 100%;
        height: auto;
      }
    }

    .nav-links {
      display: none;
    }
  }
`;

import React, { FC } from "react";
import { LayoutSC } from "./Layout.styled";
import Navbar from "@components/Navbar";
import Pages from "@pages/index";
import Footer from "@components/Footer";

interface LayoutProps {}

const Layout: FC<LayoutProps> = ({}) => {
  return (
    <LayoutSC>
      <Navbar />
      <Pages />
      <Footer />
    </LayoutSC>
  );
};

export default Layout;

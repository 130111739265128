import React, { FC } from "react";
import { ContactSC } from "./Contact.styled";
import { ReactComponent as SvgPattern } from "@assets/svg/izzy-pattern.svg";

const Contact: FC = () => {
  return (
    <ContactSC>
      <p>Contact Izzy</p>
      <h1>
        Let's talk! <span>{"=)"}</span>
      </h1>

      <h1>
        <a href="mailto:hello@izzybuirski.com">hello@izzybuirski.com</a>
      </h1>
    </ContactSC>
  );
};

export default Contact;

import styled, { keyframes } from "styled-components";

const moveDown = keyframes`
0%{
    top:10%;
}
50%{
    top:35%;
}
100%{
    top:50%;
}
`;

export const HomeSC = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  background-color: white;
  padding: 0rem 4rem;

  .header {
    padding: 4rem 0rem 2rem 0rem;
    display: grid;
    grid-template-columns: 1fr;
    justify-content: space-between;
    align-items: center;
    h1 {
      font-weight: 400;
      font-size: 4.5rem;
      text-align: left;
      letter-spacing: -0.02em;

      @media only screen and (max-width: 1024px) {
        font-size: 1.5rem;
      }
    }

    p {
      font-size: 2rem;

      span {
        font-weight: 500;
      }
    }

    a {
      text-decoration: none;
      p {
        margin-top: 2rem;
      }
    }
  }

  p {
    text-align: left;
    font-weight: 300;
    font-size: 1.5rem;
    margin: 0px;
    letter-spacing: 0.08em;

    @media only screen and (max-width: 1024px) {
      font-size: 1rem;
    }
  }

  .container {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-auto-rows: 100px 300px;
    grid-gap: 10px;
    grid-auto-flow: dense;
    margin-bottom: 4rem;

    @media only screen and (max-width: 1024px) {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }

  .gallery-item {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .gallery-item .image {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .gallery-item .image img {
    width: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    cursor: pointer;
    transition: 0.5s ease-in-out;
    height: 100%;

    @media only screen and (min-width: 1024px) {
      height: 100%;
    }
  }
  .gallery-item:hover .image img {
    transform: scale(1.05);
  }

  .gallery-item .text {
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 25px;
    pointer-events: none;
    z-index: 4;
    transition: 0.3s ease-in-out;
    -webkit-backdrop-filter: blur(5px) saturate(1.8);
    backdrop-filter: blur(5px) saturate(1.8);
  }

  .gallery-item:hover .text {
    opacity: 1;
    animation: ${moveDown} 0.3s linear;
    padding: 1em;
    width: 100%;
  }

  .w-1 {
    grid-column: span 1;
  }
  .w-2 {
    grid-column: span 2;
  }
  .w-3 {
    grid-column: span 3;
  }
  .w-4 {
    grid-column: span 4;
  }
  .w-5 {
    grid-column: span 5;
  }
  .w-6 {
    grid-column: span 6;
  }

  .h-1 {
    grid-row: span 1;
  }
  .h-2 {
    grid-row: span 2;
  }
  .h-3 {
    grid-row: span 3;
  }
  .h-4 {
    grid-row: span 4;
  }
  .h-5 {
    grid-row: span 5;
  }
  .h-6 {
    grid-row: span 6;
  }

  @media screen and (max-width: 500px) {
    .container {
      grid-template-columns: repeat(1, 1fr);
    }
    .w-1,
    .w-2,
    .w-3,
    .w-4,
    .w-5,
    .w-6 {
      grid-column: span 1;
    }
  }

  @media only screen and (max-width: 776px) {
    padding: 0rem 1rem;
    gap: 1rem;

    .header {
      padding: 2rem 0rem;
      grid-template-columns: 1fr;

      p {
        font-size: 1rem;
      }
    }
  }
`;

export const HomeHeroSC = styled.div`
  h1 {
    font-weight: 400;
  }
`;

import styled from "styled-components";

export const SplashScreenSC = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;

  .content {
    text-align: center;

    h1 {
      font-weight: 400;
      margin: 1rem 0rem;
      letter-spacing: -0.02em;
    }

    p {
      font-family: "Neuzeit Grotesk Regular";
      font-weight: 400;
      letter-spacing: 0.2em;
    }
  }

  img {
    max-width: 300px;
  }
`;

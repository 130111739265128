import React, { FC } from "react";
import { SplashScreenSC } from "./SplashScreen.styled";
import logoGif from "@assets/animations/izzybuirski.gif";
import AnimatedFadeDiv from "@components/AnimatedFadeDiv";

const SplashScreen: FC = () => {
  return (
    <AnimatedFadeDiv>
      <SplashScreenSC>
        <div className="content">
          <img src={logoGif} alt="logo-animation" />
          <h1>Izzy Buirski</h1>
          <p>Design & Branding</p>
        </div>
      </SplashScreenSC>
    </AnimatedFadeDiv>
  );
};

export default SplashScreen;

import styled, { keyframes } from "styled-components";
import { Link } from "react-router-dom";

export const CaseStudiesSC = styled.div`
  border-top: 0.5px solid black;

  h2 {
    font-weight: 500;
    font-size: 2rem;
    text-align: left;
  }
  .cases-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
  }

  @media only screen and (max-width: 776px) {
    h2 {
      font-size: 1.5rem;
    }
    .cases-wrapper {
      grid-template-columns: 1fr;
    }
  }
`;

export const CaseStudyCardSC = styled(Link)`
  width: 100%;
  cursor: pointer;
  text-decoration: none;
  text-align: left;

  .case-study-image {
    width: 100%;
    height: 500px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 0.3s;

      &:hover {
        transform: scale(1.1);
      }
    }
  }

  @media only screen and (max-width: 776px) {
    .case-study-content {
      h1 {
        font-size: 1.5rem;
      }
    }

    .case-study-image {
      height: 300px;
      img {
        height: 100%;
        width: 100%;
      }
    }
  }
`;

import React, { FC } from "react";
import { CaseStudiesSC } from "./CaseStudies.styled";
import { caseStudyData } from "./caseStudyData";
import CaseStudyCard from "./components/CaseStudyCard";

const CaseStudies: FC = () => {
  return (
    <CaseStudiesSC>
      <h2>Recent Work</h2>
      <div className="cases-wrapper">
        {caseStudyData.map((caseStudy, index) => {
          return (
            <CaseStudyCard
              key={index}
              images={caseStudy.images}
              title={caseStudy.title}
              description={caseStudy.description}
              url={caseStudy.url}
            />
          );
        })}
      </div>
    </CaseStudiesSC>
  );
};

export default CaseStudies;

import styled from "styled-components";

export const FooterSC = styled.div`
  text-align: left;
  padding: 4rem 4rem 1rem 4rem;

  form {
    gap: 1rem;
    input {
      padding: 0.5rem 1rem;
      height: 100%;
      border: 0.75px solid var(--secondary-color);
    }
  }
  h1 {
    font-weight: 400;
  }

  p {
    line-height: 160%;
  }

  .upper-footer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-bottom: 4rem;

    .content {
      text-align: right;
    }

    .images {
      display: flex;
      gap: 1rem;
      img {
        width: 100%;
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    padding: 2rem 1rem;

    .upper-footer {
      grid-template-columns: 1fr;
    }
  }
`;
